<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"

            
            class="font-weight-bold mb-1"
          >
            Création du mot de passe
          </b-card-title>
          <b-card-text class="mb-2">
            Votre mot de passe doit être différent des précédents mot de passe
            utilisés
          </b-card-text>

          <!-- form -->
          <!-- @submit.prevent="OnSetPasswordAgent" -->
          <div
            class="auth-reset-password-form mt-2"
            method="POST"
            
          >
            <!-- email -->
            <b-form-group
              v-if="!$route.params.email"
              label="Email"
              label-for="email"
              required
            >
              <b-form-input
                v-model="form.email"
                type="email"
                name="email"
              />
            </b-form-group>

            <div v-else>
              <strong>Email: </strong> {{$route.params.email}} <br> <br>
            </div>

            <!-- password -->
            <PasswordInputField v-model="form.password" />

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="$v.form.cPassword.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-confirm"
                  v-model="form.cPassword"
                  class="form-control-merge"
                  :type="password2FieldType"
                  :state="$v.form.cPassword.$error ? false : null"
                  name="reset-password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password2ToggleIcon"
                    @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>

              <div v-if="$v.form.cPassword.$error">
                <small
                  v-if="$v.form.cPassword.isValid.$invalid"
                  class="text-danger"
                >Les mot de passe ne sont pas identiques</small>
              </div>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              @click="OnSetPasswordAgent()"
            >
              <!--  -->
              <b-spinner
                v-if="isLoading"
                small
              />
              Définir le mot de passe
            </b-button>
          </div>

          <p class="text-center mt-2">
            <b-link href="/login">
              <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup, 
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import PasswordInputField from '@/components/PasswordInputField.vue'
import store from '@/store/index'
import AppLogo from '@/components/AppLogo.vue'
import passwordValidators from '@/helpers/password-validators'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    PasswordInputField,
    AppLogo,
    BSpinner,
  },
  setup: () => ({ passwordValidators, $v: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      isLoading: false,
      userEmail: '',
      form: {
        email: '',
        cPassword: '',
        password: '',
      },
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  validations() {
    return {
      form: {
        email: { required },
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid
          },
        },
        cPassword: {
          isValid: val => val && val === this.form.password,
        },
      },
    }
  },
  mounted(){
    if(this.$route.params.email) this.form.email = this.$route.params.email
  },
  methods: {
    ...mapActions('auth', {
      action_setPasswordAgent: 'setPasswordAgent',
    }),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async  OnSetPasswordAgent() {
      if (this.isLoading) return
      this.isLoading = true
      const isFormCorrect = await this.$v.$validate()
      // console.log('this.$v: ', this.$v)
      // console.log('isFormCorrect: ', isFormCorrect);

      if (!isFormCorrect) {
        this.isLoading = false
        return
      }

      // const emailUser = this.$route.params.email
      //   ? this.$route.params.email
      //   : this.form.email


      // console.log('emailUser: ', emailUser);

      const param = { email: this.form.email, password: this.form.cPassword }
      
      // console.log('param: ', param);
      // console.log('---- param: ---- ', param);

      this.action_setPasswordAgent(param)
        .then(res => {
          console.log(' -------- response when reset password: -------- ', res)
          this.isLoading = false
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Mot de passe créé avec succèss',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'login',
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
